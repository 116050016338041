import { CSSProperties, ReactNode } from 'react'
import { ControlSize } from 'types/src/controls'
import { Container } from './style'

type Props = {
    children?: ReactNode
    className?: string
    color: string
    size: ControlSize
    style?: CSSProperties
    textColor?: string
    title?: string
}

const Badge = ({ children, className, color, size, style, textColor, title }: Props) => (
    <Container $color={color} $size={size} $textColor={textColor} className={className} style={style} title={title}>
        {children}
    </Container>
)

export default Badge
